import { useContext, useState } from 'react'
import {
  Modal,
  Divider,
  Select,
  Tooltip,
  TextInput,
  Loader,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'
import { useQuery, useMutation } from '@redwoodjs/web'
import AvatarGroup from 'src/components/DataDisplay/AvatarGroup/AvatarGroup'
import Button from 'src/components/Buttons/Button/Button'
import CheckboxMenu from 'src/components/Overlays/CheckboxMenu/CheckboxMenu'
import DeleteModal from 'src/components/Overlays/DeleteModal/DeleteModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_JOB_LISTINGS,
  UPDATE_JOB_LISTING,
} from 'src/graphql/joblistings.graphql'
import {
  GET_TRACKING_LINKS,
  CREATE_TRACKING_LINK,
  DELETE_TRACKING_LINK,
} from 'src/graphql/trackinglinks.graphql'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import IconAdd from 'src/icons/IconAdd'
import IconChevronDown from 'src/icons/IconChevronDown'
import { formatAddress } from 'src/lib/address.utils'

import TrackingLink from './components/TrackingLink/TrackingLink'
import { RecruitersContext } from 'src/context/RecruitersContext'

const JobListingDetailsModal = ({ job, opened, close }) => {
  const trackingLinkSchema = z.object({
    linkType: z.string(),
    linkName: z.string().max(50).optional(),
  })

  const trackingLinkForm = useForm({
    initialValues: {
      linkType: '',
      linkName: '',
    },
    validate: zodResolver(trackingLinkSchema),
  })

  const [creatingNewLink, setCreatingNewLink] = useState(false)
  const [openedDelete, deleteHandlers] = useDisclosure(false)
  const [selectedLink, setSelectedLink] = useState()
  const recruiters = useContext(RecruitersContext)
  const [selectedRecruiters, setSelectedRecruiters] = useState(
    job?.employees.map((employee) => ({
      label: employee.firstName + ' ' + employee.lastName,
      value: employee.employeeId,
    })) || []
  )

  /**********************    QUERIES    **********************/
  const { data, loading: trackingLinksLoading } = useQuery(GET_TRACKING_LINKS, {
    variables: { jobListingId: job?.jobListingId },
  })
  const trackingLinks = trackingLinksLoading
    ? []
    : data?.trackingLinks?.items ?? []

  /**********************    MUTATIONS    **********************/
  const [updateJobListing] = useMutation(UPDATE_JOB_LISTING, {
    variables: {
      id: job?.jobListingId,
      input: {
        recruiters: [],
      },
    },
    refetchQueries: [GET_JOB_LISTINGS],
    onCompleted: () => {
      toast('Recruiters updated successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong', 'error')
    },
  })

  const [createTrackingLink] = useMutation(CREATE_TRACKING_LINK, {
    refetchQueries: [
      {
        query: GET_TRACKING_LINKS,
        variables: { jobListingId: job?.jobListingId },
      },
    ],
    onCompleted: () => {
      toast('Link created successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong', 'error')
    },
  })

  const [deleteTrackingLink] = useMutation(DELETE_TRACKING_LINK, {
    variables: {
      id: selectedLink,
    },
    refetchQueries: [
      {
        query: GET_TRACKING_LINKS,
        variables: { jobListingId: job?.jobListingId },
      },
    ],
    onCompleted: () => {
      toast('Link deleted successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong', 'error')
    },
  })

  const links = Object.entries(LinkTypeDisplay).map(([value, label]) => ({
    label,
    value,
  }))

  /**********************    FUNCTIONS    **********************/
  const handleNewLink = (values) => {
    createTrackingLink({
      variables: {
        input: {
          linkType: values.linkType,
          linkName: values.linkName,
          jobListingId: job?.jobListingId,
        },
      },
    })
    setCreatingNewLink(false)
  }

  const handleDeleteModal = (link) => {
    setSelectedLink(link)
    deleteHandlers.open()
  }

  const handleCheckboxClose = () => {
    const updatedRecruiters = selectedRecruiters.map(
      (recruiter) => recruiter.value
    )
    const employeeIds = new Set(
      job?.employees.map((employee) => employee.employeeId)
    )
    // Compare lengths of the sets
    const isLengthDifferent = updatedRecruiters.size !== employeeIds.size

    // Check if every element in one is present in the other
    const isContentDifferent = updatedRecruiters.some(
      (id) => !employeeIds.has(id)
    )

    if (isLengthDifferent || isContentDifferent) {
      updateJobListing({
        variables: {
          id: job?.jobListingId,
          input: {
            recruiters: updatedRecruiters,
          },
        },
      })
    }
  }

  return (
    <Modal.Root opened={opened} onClose={close} centered size="45%">
      <DeleteModal
        title="Are you sure that you want to delete this link?"
        opened={openedDelete}
        close={deleteHandlers.close}
        onDelete={deleteTrackingLink}
      />
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <div className="flex flex-col gap-0.5">
              <div className="text-lg font-bold">{job?.title}</div>
            </div>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body>
          <div className="grid grid-cols-1 gap-1">
            <div className="text-sm font-semibold text-doubleNickel-gray-600">
              Location:
            </div>
            <div className="text-sm text-doubleNickel-gray-900">
              {formatAddress(job?.address)}
            </div>
            <div className="text-sm font-semibold text-doubleNickel-gray-600">
              Posted Date:
            </div>
            <div className="text-sm text-doubleNickel-gray-900">
              {dayjs(job?.jobPostingDate).format('MM/DD/YYYY')}
            </div>
            <div className="text-sm font-semibold text-doubleNickel-gray-600">
              Recruiters:
            </div>
            <div className="text-sm text-doubleNickel-gray-900">
              <CheckboxMenu
                items={recruiters}
                selectedItems={selectedRecruiters}
                setSelectedItems={setSelectedRecruiters}
                fallbackText={'No recruiters found'}
                onClose={handleCheckboxClose}
                position="bottom-start"
              >
                <div className="flex items-center gap-2">
                  <AvatarGroup
                    avatars={selectedRecruiters
                      .map((selected) => {
                        const employee = recruiters.find(
                          (employee) => employee.value === selected.value
                        )

                        if (!employee) return null

                        return {
                          firstName: employee.label.split(' ')[0],
                          lastName: employee.label.split(' ')[1],
                        }
                      })
                      .filter((employee) => employee)}
                  />
                  <IconChevronDown />
                </div>
              </CheckboxMenu>
            </div>
            <Divider className={'border-doubleNickel-gray-200'} />
            <div className="flex flex-col gap-4">
              <div className="justify-content-between flex w-full items-center py-2">
                <div className="text-md font-bold text-doubleNickel-gray-600">
                  Tracking Links
                </div>
                <Tooltip label="Track conversions by channel and campaign to assess performance.">
                  <Button
                    variant="outline"
                    text="Create link"
                    lefticon={<IconAdd />}
                    onClick={() => setCreatingNewLink(true)}
                    className="ml-auto"
                  />
                </Tooltip>
              </div>
              {trackingLinksLoading ? (
                <div className="flex items-center justify-center">
                  <Loader size={40} />
                </div>
              ) : (
                trackingLinks.map((link) => (
                  <TrackingLink
                    link={link}
                    key={link.trackingLinkId}
                    handleDeleteModal={handleDeleteModal}
                  />
                ))
              )}
              {creatingNewLink && (
                <>
                  <Divider />
                  <form
                    className="grid grid-cols-2 gap-4 transition-all"
                    onSubmit={trackingLinkForm.onSubmit(handleNewLink)}
                  >
                    <Select
                      data={links}
                      placeholder="Select link type"
                      label="Link Type"
                      className="gap-1 transition-all"
                      classNames={{
                        label:
                          'text-sm font-medium text-doubleNickel-gray-700 ',
                      }}
                      searchable
                      withAsterisk
                      {...trackingLinkForm.getInputProps('linkType')}
                    />
                    <TextInput
                      label="Link Name"
                      placeholder="Type the link name here"
                      withAsterisk
                      {...trackingLinkForm.getInputProps('linkName')}
                    />
                    <Button
                      text="Cancel"
                      variant="outline"
                      onClick={() => setCreatingNewLink(false)}
                    />
                    <Button
                      text="Save"
                      disabled={!trackingLinkForm.isValid()}
                      variant="filled"
                      type="submit"
                    />
                  </form>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}

export default JobListingDetailsModal
